.footer-container{
    position: relative;
}
.footer-container>hr{
    border:1px solid lightgray;
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap:4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;

}
.social-links{
   display: flex;
   gap:4rem;
}
.social-links>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}
.logo-footer>img{
  width: 10rem;
}
.foot-blur-1{
   bottom: 0;
   right: 10%;
   width: 40rem;
   height: 12rem;
   filter: blur(100px);
   

}
.foot-blur-2{
  bottom: 0;
  left: 10%;
   width: 40rem;
   height: 12rem;
   filter: blur(100px);
}